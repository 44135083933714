<template>
  <div id="wave" :style="cssVars">
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </div>
</template>

<script>
export default {
  name: "TextLoader",
  props: {
    size: {
      type: [Number, String],
      default() {
        return "8px"
      }
}
  },
  computed: {
    comp() {
      return {
        size: this.size
      }
    },
    cssVars() {
      return {
        "--triple-dots-size": this.comp.size + "px",
        "--triple-dots-spacing": this.comp.spacing + "px",
        "--triple-dots-delta-left": this.comp.left + "px"
      }
    }
  }
}
</script>

<style lang="scss">

div#wave {
  position: relative;
  display: inline-block;
  text-align: center;

  .dot {

    &:not(:last-child) {
      margin-right: .7em;
    }

    display:inline-block;
    font-size: var(--triple-dots-size);
    width: var(--triple-dots-size);
    height: var(--triple-dots-size);
    border-radius:50%;
    background: var(--v-primary-base);
    animation: wave 1.3s cubic-bezier(.8, .3, .2, 1.4) infinite;

    &:nth-child(2) {
      animation-delay: -1.1s;
    }

    &:nth-child(3) {
      animation-delay: -0.9s;
    }
  }
}

@keyframes wave {
  0%, 60%, 100% {
    transform: initial;
  }

  30% {
    transform: translateY(-2em);
  }
}

</style>